import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import HomeRoutes from './HomeRoutes';

import { LoginRoutes, noMainRoutes } from './LoginRoutes';
import ProfileRoutes from './ProfileRoutes';
import { AgreementRoutes, EmptyLayoutRoutes } from './AgreementRoutes';
import { getSystemOptions, setSystemOptions } from 'utils/common';
import { useEffect } from 'react';
import { API } from 'utils/api';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useRoutes([
    HomeRoutes, //修改处
    noMainRoutes,
    LoginRoutes, //修改处
    ProfileRoutes, //修改处
    AgreementRoutes, //修改处
    EmptyLayoutRoutes
  ]);
  const getOptions = async () => {
    const { success, data } = (await API.get('/api/option/')).data;
    if (success) {
      setSystemOptions(data);
    }
    return data;
  };
  useEffect(() => {
    const checkMaintainPage = async () => {
      let systemOptions = await getOptions();
      if (systemOptions) {
        const item = systemOptions.find((e) => e.key === 'ShowMaintainPage');
        const ShowMaintainPage = item?.value === 'true';
        if (ShowMaintainPage && location.pathname !== '/maintain') {
          navigate('/maintain');
        }
      }
    };
    checkMaintainPage();
  }, [location.pathname, navigate]);
  return routes;
}
