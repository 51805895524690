import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import { getMenuItems } from 'menu-items/profile';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { useHeaderVisibility } from '../../hooks/useHeaderVisibility';

// assets
import { IconChevronRight } from '@tabler/icons-react';

import { Box } from '@mui/system';
import { AppBar, CssBaseline, Grid, Toolbar } from '@mui/material';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, shouldDisplayHeader }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const AgreementLayout = () => {
  const location = useLocation();
  const navigation = getMenuItems();
  const shouldDisplayHeader = useHeaderVisibility() && location.pathname != '/maintain';
  const theme = useTheme();

  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {shouldDisplayHeader && (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
            height: '56px',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06)'
          }}
        >
          <Toolbar sx={{ py: '17px', pl: '20px' }}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      )}

      <Main
        theme={theme}
        open={leftDrawerOpened}
        style={{
          marginTop: shouldDisplayHeader ? '' : '80px',
          background:
            location.pathname == '/profile/index' || location.pathname == '/profile/topup/index'
              ? '#f5f7ff'
              : 'linear-gradient(rgb(230, 234, 254) 10%, rgb(255, 255, 255) 70%)',
          borderRadius: '0px'
        }}
      >
        <div style={{ minHeight: shouldDisplayHeader ? 'calc(100vh - 98px)' : '100vh', display: 'flex', flexDirection: 'column' }}>
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <Grid flex={1} style={{ display: 'flex', flexDirection: 'column' }}>
            <Outlet />
          </Grid>
        </div>
      </Main>
    </Box>
  );
};

export default AgreementLayout;
