import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import ThemeButton from 'ui-component/ThemeButton';
import { useSelector } from 'react-redux';
// assets
import { IconMenu2 } from '@tabler/icons-react';

import logo from 'assets/images/auth/logo.svg';
import question from 'assets/images/auth/question.svg';
import { useNavigate } from 'react-router';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const siteInfo = useSelector((state) => state.siteInfo);
  const navigate = useNavigate();
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          {/* <LogoSection /> */}
          <img
            src={logo}
            alt="logo"
            style={{ height: '22px', margin: '17px 0', cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          />
        </Box>
        {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <a href={siteInfo.document_address} target="_blank" style={{ textDecoration: 'none' }}>
        <Typography variant="subtitle2" color="#000022D9" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
          <img src={question} alt="question" style={{ verticalAlign: 'top', width: '18px', height: '20px', marginRight: '5px' }} />
          文档中心
        </Typography>
      </a>

      <ProfileSection />
      {/* 
      <ThemeButton /> 
      
      */}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
