import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from 'react-router-dom';

const Header = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
              variant="mainTitle"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewOutlinedIcon style={{ fontSize: '18px', marginRight: '12px', marginBottom: '2px', color: '#00002280' }} />
              <span>返回</span>
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
