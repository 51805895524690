import { Outlet } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, Container } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { useHeaderVisibility } from '../../hooks/useHeaderVisibility';
import loginbg from 'assets/images/auth/login-bg.svg';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const shouldDisplayHeader = useHeaderVisibility();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        //background: 'linear-gradient(to right, #6a5acd, #ffc0cb)',
        backgroundImage: `url(${loginbg})`,
        backgroundSize: 'cover', // 如果需要让背景图片覆盖整个元素
        backgroundPosition: 'center' // 背景图片居中
      }}
    >
      <CssBaseline />
      {shouldDisplayHeader && (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: 'transparent',
            flex: 'none'
          }}
        >
          <Container sx={{ mr: 0 }}>
            <Toolbar>
              <Header />
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <Box sx={{ flex: '1 1 auto', overflow: 'auto' }} marginTop={shouldDisplayHeader ? '80px' : '0px'}>
        <Outlet />
      </Box>
      <Box sx={{ flex: 'none', background: !shouldDisplayHeader ? '#E9E9E9' : '' }}>
        <Footer showFilings={shouldDisplayHeader} />
      </Box>
    </Box>
  );
};

export default MinimalLayout;
