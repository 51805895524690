// material-ui
import logoLight from 'assets/images/logo.png';
import logoDark from 'assets/images/logo.png';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/auth/logo.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const siteInfo = useSelector((state) => state.siteInfo);
  // const theme = useTheme();
  // const logo = theme.palette.mode === 'light' ? logoLight : logoDark;

  return (
    <img
      style={{ width: '80%', maxWidth: '220px', objectFit: 'contain' }}
      src={siteInfo.logo || logo}
      alt={siteInfo.system_name}
      height="50"
    />
  );
};

export default Logo;
