import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();

  const groupes = item.children.reduce((acc, user) => {
    (acc[user.group] = acc[user.group] || []).push(user);
    return acc;
  }, {});
  const isShutSiderBar = useSelector((state) => state.customization.opened);
  const menuList = [];
  Object.keys(groupes).forEach((key, index) => {
    const menus = groupes[key];
    const res = menus?.map((menu) => {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} hasChildren={menu.children?.length > 0}></NavItem>;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    const itmes = (
      <>
        {key && isShutSiderBar && (
          <Typography
            variant="subtitle3"
            sx={{
              display: 'inline-block',
              textDecoration: 'none',
              m: '10px 12px',
              //height:'36px',
              verticalAlign: 'middle'
            }}
          >
            {key == 'undefined' ? '' : key}
          </Typography>
        )}
        {res}
        {index != Object.keys(groupes).length - 1 && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
      </>
    );

    menuList.push(itmes);
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {menuList.flat()}
      </List>

      {/* group divider */}
      {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
