import { lazy } from 'react';
import ProfileLayout from 'layout/ProfileLayout';
import Loadable from 'ui-component/Loadable';

const Topup = Loadable(lazy(() => import('views/Topup')));
const ProfileNew = Loadable(lazy(() => import('views/Profile_new')));
const OrderManageNew = Loadable(lazy(() => import('views/OrderManage_new')));
const OrderConsume = Loadable(lazy(() => import('views/OrderConsume')));
const TeamManage = Loadable(lazy(() => import('views/TeamManage_new')));
const PrivacyAgreement = Loadable(lazy(() => import('views/PrivacyAgreement')));
const UserAgreement = Loadable(lazy(() => import('views/UserAgreement')));

const ProfileRoutes = {
  path: '/profile',
  element: <ProfileLayout />,
  children: [
    {
      path: 'order/manage/index',
      element: <OrderManageNew />
    },
    {
      path: 'order/consume/index',
      element: <OrderConsume />
    },
    {
      path: 'index',
      element: <ProfileNew />
    },
    {
      path: 'topup/index',
      element: <Topup />
    },
    {
      path: 'team_manage/index',
      element: <TeamManage />
    },
    {
      path: 'privacy/agreement/index',
      element: <PrivacyAgreement />
    },
    {
      path: 'user/agreement/index',
      element: <UserAgreement />
    }
  ]
};

export default ProfileRoutes;
