// assets
import { IconChartArea, IconMessage, IconPictureInPicture, IconMenuOrder } from '@tabler/icons-react';
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconLayoutGrid,
  IconDevicesPlus,
  IconUserHeart,
  IconBoxModel,
  IconTag,
  IconSettingsBolt,
  // IconPictureInPicture,
  // IconMessage,
  IconDeviceAudioTape
} from '@tabler/icons-react';
import { isShowFinetuneMenu, isShowTeamMenu } from 'utils/common';
import userSet from 'assets/images/users/user-set.svg';
import bill from 'assets/images/users/bill.svg';
import recharge from 'assets/images/users/recharge.svg';
// constant
const icons = {
  IconDashboard,
  IconLayoutGrid,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconDevicesPlus,
  IconChartArea
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const getProfileMenu = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'profile',
        title: '账户设置',
        type: 'item',
        url: '/profile/index',
        icon: userSet,
        breadcrumbs: false,
        isAdmin: false
      },
      {
        id: 'topup',
        title: '充值兑换',
        type: 'item',
        url: '/profile/topup/index',
        icon: recharge,
        breadcrumbs: false
      },
      {
        id: 'order',
        title: '账单明细',
        type: 'item',
        url: '/profile/order/manage/index',
        icon: bill,
        breadcrumbs: false
      },
      {
        id: 'order_consume',
        title: '账单消耗',
        type: 'item',
        url: '/profile/order/consume/index',
        icon: bill,
        breadcrumbs: false
      },
      ...(isShowTeamMenu()
        ? [
            {
              id: 'team_manage',
              title: '团队管理',
              type: 'item',
              url: '/profile/team_manage/index',
              icon: userSet,
              breadcrumbs: false,
              isAdmin: false
            }
          ]
        : [])
    ]
  };
};

export { getProfileMenu };
