import { getProfileMenu } from './profile_menu';
// import { getPanel } from './panel1'; //修改处

// ==============================|| MENU ITEMS ||============================== //

const getMenuItems = () => {
  const menuItems = {
    items: [getProfileMenu()],
    urlMap: {}
  };

  // Initialize urlMap
  menuItems.urlMap = menuItems.items.reduce((map, item) => {
    item.children.forEach((child) => {
      map[child.url] = child;
    });
    return map;
  }, {});
  return menuItems;
};

export { getMenuItems };
